import {
  IconTMSReady,
  IconTenderAcceptance,
  IconVisibility,
  IconHassleFree,
  IconDeal,
  IconAutomateInvoicing,
} from '@loadsmart/icons';

import { Container } from '@/components/Grid';
import { Section } from '@/components/Grid/Section';
import { Title, Text } from '@/components/Grid/Content';

import {
  FeatureIcon,
  FeatureTitle,
  FeatureDescription,
  responsiveIconStyle,
  StyledFeature,
  StyledFeatureGroup,
} from '@/components/Feature';

export const FeatureSection = () => (
  <Section>
    <Container>
      <Title>
        Start pricing and booking freight all in one place with flexible TMS
        integrations
      </Title>
      <Text>
        Enterprise logistics runs more efficiently when shippers are free to
        operate within their TMS. With integrated, flexible freight capacity,
        shippers lower costs while meeting service requirements and efficiency
        goals. With access to instant quotes on any lane and added capacity
        baked into their TMS, shippers anchor decisions with real-time market
        rates informed by more than 500 data points, including fuel, weather,
        and driver availability.
      </Text>
      <Text>
        Loadsmart delivers rates to your TMS that are instantly bookable,
        meaning a truck is associated with each price. With that comes the
        industry’s first-ever guarantee of 100% tender acceptance — we never
        change the price or give back the load. Start by benchmarking rates for
        informed decision-making with zero obligation.
      </Text>
      <StyledFeatureGroup>
        <StyledFeature>
          <FeatureIcon>
            <IconTMSReady role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>TMS READY</FeatureTitle>
          <FeatureDescription>
            Pre-built, no-cost TMS integrations that are fast and easy to
            enable.
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconTenderAcceptance role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>GUARANTEED TENDER ACCEPTANCE</FeatureTitle>
          <FeatureDescription>
            100% tender acceptance, no price changes, and no returned labels.
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconVisibility role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>MAXIMUM VISIBILITY</FeatureTitle>
          <FeatureDescription>
            Real-time tracking and status updates delivered directly to your TMS
            via API/EDI.
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconHassleFree role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>FLEXIBLE CAPACITY</FeatureTitle>
          <FeatureDescription>
            Our carriers are vast and vetted, giving you great capacity and
            faster coverage.
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconDeal role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>DEDICATED ACCOUNT MANAGEMENT</FeatureTitle>
          <FeatureDescription>
            Your personal industry expert and operations team moving your
            freight on time.
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconAutomateInvoicing role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>AUTOMATE INVOICING</FeatureTitle>
          <FeatureDescription>
            Speedy, paperless invoicing, through EDI integration.
          </FeatureDescription>
        </StyledFeature>
      </StyledFeatureGroup>
    </Container>
  </Section>
);
