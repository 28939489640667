import { useRouter } from 'next/router';

//helpers
import { sleep } from '@/helpers/sleep';

//components
import { ButtonHero } from '@/components/Button';

//containers
import { Title, Description } from '@/containers/Hero/Hero.styles';

// context containers
import {
  smallFont,
  ctaStyle,
  FlexRowWrap,
  HeroBackground,
} from './HeroSection.styles';

const responsiveBackground = {
  sm: `url(/images/mode-optimization/small_hero.jpg)`,
  md: `url(/images/mode-optimization/medium_hero.jpg)`,
  lg: `url(/images/mode-optimization/large_hero.jpg)`,
  xxlg: `url(/images/mode-optimization/xxlarge_hero.jpg)`,
};

export const HeroSection = () => {
  const router = useRouter();
  const handleFormJump = async (event) => {
    event.preventDefault();
    // analytics.track(' Page / Request Demo');
    await sleep(300);
    router.push('#hubspot-form');
  };

  return (
    <HeroBackground image={responsiveBackground}>
      <Title>Mode Optimization</Title>
      <Description>
        Select from multiple instantly bookable modes for a single shipment.
      </Description>
      <FlexRowWrap>
        <ButtonHero as="a" onClick={handleFormJump} css={[ctaStyle, smallFont]}>
          Talk to a Transportation Specialist
        </ButtonHero>
      </FlexRowWrap>
    </HeroBackground>
  );
};
