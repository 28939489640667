import styled from 'styled-components';
import { screen } from '@/helpers/screen';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const ServicesWrapper = styled.section`
  padding: 100px 0;
  background-color: rgb(${getToken('color-background-primary')});
  text-align: center;
`;

export const Title = styled.h2`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-brand')});
  font-weight: ${getToken('font-weight-bold')};
  font-size: 38px;
  line-height: 47.5px;
  text-align: center;
  text-wrap: balance;

  margin: 0;

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const Content = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-tertiary')});
  font-weight: ${getToken('font-weight-regular')};
  margin: 32px auto 48px;

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 30px;
    margin: 32px auto 48px;
    max-width: 1209px;
  }
`;

export const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 24px auto;
  justify-content: flex-start;
  gap: 0 6px;

  ${screen.md} {
    gap: 8px 24px;
    justify-content: space-between;
  }

  ${screen.lg} {
    gap: 42px;
    justify-content: center;
  }
`;

export const LegendItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin: 10px 0;
  text-wrap: balance;

  span {
    font-family: ${getToken('platform-font-title')};
    color: rgb(${getToken('color-text-primary')});
    font-weight: ${getToken('font-weight-regular')};
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    margin-left: 2px;

    ${screen.md} {
      margin-left: 8px;
    }
  }
`;

export const ServicesList = styled.div`
  display: grid;
  gap: 16px;

  grid-template-areas:
    'a'
    'd'
    'b'
    'c';

  ${screen.md} {
    grid-template-areas:
      'a b c'
      'd d d';
  }

  ${screen.lg} {
    grid-template-areas: 'a d b c ';
    grid-template-columns: 1fr 2fr 1fr 1fr;
  }
`;

export const ServicesContainer = styled.div`
  &.block-1 {
    grid-area: a;
  }
  &.block-2 {
    grid-area: b;
  }
  &.block-3 {
    grid-area: c;
  }
  &.block-4 {
    grid-area: d;
  }
`;

export const ServicesTitle = styled.h3`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-brand')});
  font-weight: ${getToken('font-weight-bold')};
  font-weight: 700;
  text-align: left;
  margin: 24px 0;
  font-size: 15px;
  line-height: 24px;
  text-wrap: balance;

  a {
    text-decoration: none;
    color: inherit;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const ServiceItemsWrap = styled.div`
  border-top: solid 2px rgba(${getToken('color-background-inverted')}, 0.24);
  padding: 8px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ServiceItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: flex-start;
  margin: 10px 0;
  text-wrap: balance;

  width: 255px;

  ${screen.lg} {
    width: 180px;
  }
  ${screen.xxlg} {
    width: 255px;
  }

  span {
    display: inline-block;
    padding-top: 2px;
    align-items: flex-start;
    font-family: ${getToken('platform-font-title')};
    color: rgb(${getToken('color-text-primary')});
    font-weight: ${getToken('font-weight-bold')};
    font-size: 13px;
    line-height: 21px;
    text-align: left;
    margin-left: 4px;

    ${screen.md} {
      min-height: 40px;
    }

    ${screen.xxlg} {
      min-height: 46px;
      margin-left: 8px;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin: 36px auto 0;

  a,
  button {
    border-color: rgb(${getToken('color-background-primary')});
  }
`;
