//icons
import {
  IconInstantPricing,
  IconTenderAcceptance,
  IconCheckCircle,
  IconSmartTracking,
  IconInvoice,
  IconTMSReady,
} from '@loadsmart/icons';
import { IconPackageTracking, IconVisibility } from '@loadsmart/icons';
import {
  IconFastPayment,
  IconImproveEfficiency,
  IconFasterService,
} from '@loadsmart/icons';

//componets
import { WideImageOnMobile, Link } from '@/components/Grid/Content';

//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';
import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';
import { tmsIntegrationOptions } from '@/vendor/HubspotFormIntegration';

// context containers
import {
  HeroSection,
  FeatureSection,
  BackgroundImageSection,
  IntegrationAPISection,
  ContentSection,
} from './containers/index';

//styles
import { PageWrapper } from './Integrations.styles';

//content
const heroContent = {
  page: 'MercuryGate',
  logo: {
    src: `/images/logos/logo-mercury_gate.svg`,
    alt: 'MercuryGate',
  },
  title: `Supercharge your MercuryGate TMS with instant rates and automated booking`,
  description: `Loadsmart’s integration with MercuryGate makes it easy to add instant market rates, guaranteed tender acceptance, and real-time tracking directly to your MercuryGate Transportation Management System (TMS).`,
  images: {
    sm: `/images/integrations/mercury-gate/small_hero.png`,
    md: `/images/integrations/mercury-gate/medium_hero.png`,
    lg: `/images/integrations/mercury-gate/large_hero.png`,
    xxlg: `/images/integrations/mercury-gate/xxlarge_hero.png`,
  },
};
const featuresContent = {
  title: 'Shipper Benefits',
  features: [
    {
      title: 'INSTANT QUOTE FOR FTL & LTL',
      icon: <IconInstantPricing />,
    },
    {
      title: '100% TENDER ACCEPTANCE',
      icon: <IconTenderAcceptance />,
    },
    {
      title: 'AUTOMATED TRACKING',
      icon: <IconSmartTracking />,
    },
    {
      title: 'EDI INVOICING',
      icon: <IconInvoice />,
    },
    {
      title: 'NO COST, QUICK SET UP',
      icon: <IconCheckCircle />,
    },
  ],
};
const benefitsContent = {
  text: `Loadsmart’s partnerships with leading ELD providers such as KeepTruckin, combined with smart apps and other technologies like geofencing, means we always know the real-time location of your shipments and can proactively mitigate service issues before they occur.`,
  benefits: [
    {
      icon: <IconPackageTracking width={66} height={66} />,
      copy: 'View real-time tracking updates directly from within MercuryGate.',
    },
    {
      icon: <IconVisibility width={66} height={66} />,
      copy: 'Proactively identify and mitigate service issues before they occur.',
    },
  ],
};
const copyContent = {
  title: 'Supercharge Your Routing Guide',
  introduction: `Loadsmart’s integration with MercuryGate fully automates the quoting and booking of truckload shipments. We do this by inserting real-time market rates directly into your routing guide, enabling you to take advantage of market conditions when current prices are below the static options provided by other carriers. Loadsmart accepts 100% of tenders instantly — so whenever our rate is best, the load can be immediately covered in any market condition.`,
  media: (
    <WideImageOnMobile
      loading="lazy"
      src={`/images/integrations/mercury-gate/tms-landing-macbook.png`}
      alt="MercuryGate System"
    />
  ),
  benefits: [
    {
      icon: <IconFastPayment width={66} height={66} />,
      text: 'Save without re-pricing when market rate is below your static contracted options.',
    },
    {
      icon: <IconImproveEfficiency width={66} height={66} />,
      text: 'Reduce time spent in the waterfall and avoid the spot market with auto-tender based on price.',
    },
    {
      icon: <IconFasterService width={66} height={66} />,
      text: 'Control costs with customized price ceilings that only show quotes within your budget.',
    },
  ],
};
const invertedContent = {
  title: 'Instant Spot Booking with Dynamic Capacity',
  introduction: `Access to Loadsmart’s Dynamic Pricing within MercuryGate gives shippers peace of mind with the best of service, cost, and efficiency. Stop spending countless hours manually securing reliable capacity and responding to primary tender reaction, surge volume, or volatile lane changes.`,
  media: (
    <WideImageOnMobile
      loading="lazy"
      src={`/images/shipper/carousel/item-01.jpg`}
      alt="Truck on Landscape"
    />
  ),
  benefits: [
    {
      icon: <IconTMSReady width={66} height={66} />,
      text: 'Book FTL faster with instant quotes. No phone calls, emails or lost lead time finding a rate.',
    },
    {
      icon: <IconInstantPricing width={66} height={66} />,
      text: (
        <>
          Save on freight spend, get an instant quote and book LTL without
          leaving your TMS.{' '}
          <Link
            href="https://blog.loadsmart.com/2022/09/27/loadsmarts-first-of-its-kind-mercurygate-ltl-integration-provides-cost-savings-and-mode-options-for-shippers/"
            target="_blank"
            rel="noreferrer"
          >
            Read the article here.
          </Link>
        </>
      ),
    },
    {
      icon: <IconTenderAcceptance width={66} height={66} />,
      text: 'Gain confidence, knowing every tender is guaranteed to be accepted.',
    },
  ],
};
function MercuryGate() {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <HeroSection content={heroContent} />
        <FeatureSection content={featuresContent} showHeader={true} />
        <ContentSection
          content={invertedContent}
          background="light"
          direction="reverse"
          cta={{
            title: 'View Case Study',
            url: 'https://lp.loadsmart.com/case-study-packaging',
          }}
        />
        <ContentSection content={copyContent} background="dark" />
        <BackgroundImageSection content={benefitsContent} />
        <IntegrationAPISection />
        <HubspotForm {...tmsIntegrationOptions}>
          <HubspotFormTitle>Ready to learn more?</HubspotFormTitle>
          <HubspotFormDescription>
            Fill out the form below to and a member of the Loadsmart team will
            be in touch.
          </HubspotFormDescription>
        </HubspotForm>
      </>
      <Footer />
    </PageWrapper>
  );
}

export default MercuryGate;
export { MercuryGate };
