/* eslint-disable @next/next/no-html-link-for-pages */
import styled from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//icons
import {
  IconInstantPricing,
  IconTenderAcceptance,
  IconVisibility,
  IconReporting,
  IconContainerLift,
} from '@loadsmart/icons';

//components
import { Container } from '@/components/Grid/Container';
import {
  Feature,
  FeatureGroup,
  FeatureSection as Section,
  FeatureIcon,
  FeatureTitle,
  FeatureDescription,
  responsiveIconStyle,
} from '@/components/Feature';

const LastLineFeature = styled(Feature)`
  ${screen.md} {
    margin: 8px 35px;
  }

  ${screen.lg} {
    margin: 20px 48px;
  }

  ${screen.xxlg} {
    margin: 25px 90px;
  }
`;

export const FeatureSection = () => (
  <Section>
    <Container>
      <FeatureGroup>
        <Feature>
          <FeatureIcon>
            <IconInstantPricing role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>INSTANT PRICING</FeatureTitle>
          <FeatureDescription>
            Get rates in seconds and book instantly via{' '}
            <a href="/shipper/shipperguide/">ShipperGuide TMS</a>, our freight
            management platform.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureIcon>
            <IconTenderAcceptance role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>TRUSTED CARRIER PARTNERS</FeatureTitle>
          <FeatureDescription>
            We assume full responsibility, including insurance and liability,
            and rigorously screen our carriers for past performance, insurance
            and other factors.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureIcon>
            <IconVisibility role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>VISIBILITY AND TRACKING</FeatureTitle>
          <FeatureDescription>
            Track containers through the entire shipment cycle, starting from
            when the container is on the water, when it arrives at the port, are
            en-transit and ultimately arrive at your facility.
          </FeatureDescription>
        </Feature>
        <LastLineFeature>
          <FeatureIcon>
            <IconReporting role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>REPORTING</FeatureTitle>
          <FeatureDescription>
            Get the real-time status and details for every one of your
            containers.
          </FeatureDescription>
        </LastLineFeature>
        <LastLineFeature>
          <FeatureIcon>
            <IconContainerLift role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Reduce Excess Container Charges</FeatureTitle>
          <FeatureDescription>
            Ensure containers are picked up the moment they are available and
            returned before their last free day.
          </FeatureDescription>
        </LastLineFeature>
      </FeatureGroup>
    </Container>
  </Section>
);
