/* eslint-disable @next/next/no-html-link-for-pages */
import React from 'react';

//components
import { Section, Container } from '@/components/Grid';
import { Title, Text } from '@/components/Grid/Content';
import { FeatureDescription } from '@/components/Feature/Content';
import { FeatureIcon, responsiveIconStyle } from '@/components/Feature';

// context containers
import {
  StyledFeatureGroup,
  StyledFeature,
  PreventBreak,
  StyledTitle,
  StyledFeatureDescription,
} from './IntroSection.styles';

//icons
import {
  IconDevelopmentPlans,
  IconLowerCosts,
  IconFasterService,
  IconCreateValue,
} from '@loadsmart/icons';

export const IntroSection = () => (
  <Section>
    <Container>
      <Title>
        Access dynamic market rates for multiple modes and equipment types
      </Title>
      <Text>
        With reliable options and instantly bookable rates across modes
        including rail,{' '}
        <a
          target="_blank"
          href="https://lp.loadsmart.com/ltl-less-than-truckload-from-loadsmart"
          rel="noreferrer"
        >
          LTL
        </a>
        , PTL, and <a href="/ftl/">FTL</a>, you can optimize your shipments for
        sustainability, cost, and expediency. Mode optimization can be done
        automatically based on your business rules fed into an AI system,
        manually selected within your TMS, or via your Loadsmart account
        manager.
      </Text>
      <Text>
        Not sure which mode meets your freights’ requirements? You’re not alone.
        Loadsmart’s logistics experts are here to provide you with fast options
        and custom workflows catered to meet your goals. Move freight with
        confidence in knowing that every shipping decision — whether automated
        or manual — is fully informed and in line with your objectives.
      </Text>

      <StyledFeatureGroup>
        <StyledFeature>
          <FeatureIcon>
            <IconDevelopmentPlans role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <StyledTitle>Achieve Sustainability</StyledTitle>
          <StyledFeatureDescription>
            Choose the best mode to meet your emission targets
          </StyledFeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconLowerCosts role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <StyledTitle>Lower Costs</StyledTitle>
          <StyledFeatureDescription>
            Reduce overspending on freight with more options
          </StyledFeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconFasterService role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <StyledTitle>Optimize Moves</StyledTitle>
          <StyledFeatureDescription>
            Leverage data and insights to improve your decision making
          </StyledFeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconCreateValue role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <StyledTitle>Transform your TMS</StyledTitle>
          <FeatureDescription>
            Quote and book <PreventBreak>real-time</PreventBreak> rates for
            multiple modes in your TMS
          </FeatureDescription>
        </StyledFeature>
      </StyledFeatureGroup>
    </Container>
  </Section>
);
