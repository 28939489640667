/* eslint-disable @next/next/no-html-link-for-pages */
//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';
import { multimodalOptions } from '@/vendor/HubspotFormIntegration';

import {
  HeroSection,
  FeatureSection,
  BackgroundImageSection,
} from './containers';

import {
  Title,
  Text,
  Description,
  Wrapper as ContentWrapper,
  ReversedWrapper as ReversedContentWrapper,
  WideImageOnMobile,
} from '@/components/Grid/Content';

import { FlexColumnFullCentered } from '@/components/Grid/Flex';

import { Panel } from '@/components/Grid/Panel';

import {
  GradientSection,
  FloatResponsiveImage,
  LogoWrapper,
  ResponsiveSmartSchedulingLogo,
  PageWrapper,
  SidePanels,
} from './Multimodal.styles';

const Multimodal = () => {
  return (
    <>
      <PageWrapper>
        <Navigation />
        <>
          <HeroSection />
          <FeatureSection />

          <SidePanels reverse>
            <WideImageOnMobile
              src={`/images/multimodal/half_section-port_drayage.png`}
              alt="Port Drayage"
            />
            <ReversedContentWrapper>
              <Title>Port Drayage</Title>
              <Description>
                Unparalleled visibility + cost control for your drayage
                shipments
              </Description>
              <Text>
                Our free online platform makes it easy to price and tender{' '}
                <a href="/port-drayage/">port drayage</a> shipments in seconds.
                We’ve even integrated with port operators, truckers, and
                warehouses so you can improve visibility with real-time tracking
                and control costs with automated empty management.
              </Text>
            </ReversedContentWrapper>
          </SidePanels>

          <SidePanels>
            <WideImageOnMobile
              src={`/images/multimodal/half_section-transload.png`}
              alt="Transload"
            />
            <ContentWrapper>
              <Title>Transload</Title>
              <Description>
                Control costs and improve efficiency by seamlessly moving goods
                from containers to over-the-road transportation
              </Description>
              <Text>
                Loadsmart’s Transload offering bridges the gap between port
                drayage and over-the-road trucking, providing you greater
                flexibility, service and cost control when compared to rail. Our
                free web-based platform, Loadsmart Transit, enables warehouses
                to assign each PO to a trailer, so you can track goods down to
                the piece level, so you know what goods, from what containers,
                have been loaded onto what trucks.
              </Text>
            </ContentWrapper>
          </SidePanels>

          <GradientSection>
            <Panel>
              <FlexColumnFullCentered>
                <FloatResponsiveImage
                  src={`/images/multimodal/half_section-smart_scheduling.png`}
                  alt="Smart Scheduling"
                />
              </FlexColumnFullCentered>

              <ContentWrapper>
                <LogoWrapper>
                  <ResponsiveSmartSchedulingLogo />
                </LogoWrapper>
                <Description>
                  Improve on-time service by automatically selecting the best
                  possible appointment time
                </Description>
                <Text>
                  Once you tender a shipment to Loadsmart, our Smart Scheduling
                  technology automatically selects the best possible appointment
                  by analyzing estimated travel time and other factors. For
                  locations utilizing a dock management system, appointments can
                  even be selected and confirmed automatically. Should a
                  driver’s ETA change, appointments can be instantly adjusted
                  while enroute.
                </Text>
              </ContentWrapper>
            </Panel>
          </GradientSection>

          <BackgroundImageSection />

          <HubspotForm {...multimodalOptions}>
            <HubspotFormTitle>Interested? Get in touch.</HubspotFormTitle>
            <HubspotFormDescription>
              Our team is here to answer your questions and offer a personalized
              tour of Loadsmart.
            </HubspotFormDescription>
          </HubspotForm>
        </>
        <Footer />
      </PageWrapper>
    </>
  );
};

export default Multimodal;
export { Multimodal };
