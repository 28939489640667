// components
import { Container } from 'components/Grid';
import { MirandaGradientButton } from '@/components/Button';

// styles
import {
  ServicesWrapper,
  Title,
  Content,
  ButtonWrapper,
  Legend,
  LegendItem,
  ServicesList,
  ServicesContainer,
  ServicesTitle,
  ServiceItemsWrap,
  ServiceItem,
} from './Services.styles';

//assets

import IconService from '@/public/images/icons/loadsmart-services/icon-Services.svg';
import IconTech from '@/public/images/icons/loadsmart-services/icon-Tech.svg';
import IconServiceTech from '@/public/images/icons/loadsmart-services/icon-ServTech.svg';
import IconAI from '@/public/images/icons/loadsmart-services/icon-AI.svg';

const lpurl = `https://lp.loadsmart.com/loadsmarts-next-gen-transportation-logistics-solutions`;

const services_list = [
  {
    title: 'Assessment and Analysis',
    anchor: lpurl + '#assessment',
    external: true,
    order: 1,
    items: [
      {
        type: 'service',
        name: 'Freight Planning and Forecasting',
        anchor: lpurl + '#a_01',
        external: true,
      },
      {
        type: 'service-tech',
        name: 'Network Assessments, Optimization, and Strategy',
        anchor: lpurl + '#a_02',
        external: true,
      },
      {
        type: 'ai',
        name: 'FreightIntel AI',
        anchor: 'https://lp.loadsmart.com/freight-intel-ai',
        external: true,
      },
      {
        type: 'service-tech',
        name: 'Rate Analysis and Benchmarking',
        anchor: lpurl + '#a_04',
        external: true,
      },
    ],
  },
  {
    title: 'Freight Management and Execution',
    anchor: lpurl + '#freight',
    external: true,
    order: 4,
    items: [
      {
        type: 'tech',
        name: 'Transportation Management System',
        anchor: '/shipper/shipperguide/',
        external: false,
      },
      {
        type: 'tech',
        name: 'Mode and Route Optimization',
        anchor: lpurl + '#f_03',
        external: true,
      },
      {
        type: 'service-tech',
        name: 'Tracking & Visibility',
        anchor: lpurl + '#f_04',
        external: true,
      },
      {
        type: 'tech',
        name: 'RFP and Mini-bids',
        anchor: lpurl + '#f_05',
        external: true,
      },

      {
        type: 'tech',
        name: 'Order Consolidation',
        anchor: lpurl + '#f_06',
        external: true,
      },

      {
        type: 'tech',
        name: 'Real-time Carrier and Broker Spot Bidding',
        anchor: lpurl + '#f_07',
        external: true,
      },
      {
        type: 'service',
        name: 'Freight Brokerage',
        anchor: lpurl + '#f_08',
        external: true,
      },
      {
        type: 'service',
        name: 'Managed Transportation',
        anchor: '/shipper/managed-transportation/',
        external: false,
      },
      {
        type: 'service-tech',
        name: 'Carrier Sourcing, Procurement, and Management',
        anchor: lpurl + '#f_10',
        external: true,
      },
    ],
  },
  {
    title: 'Gate, Yard, and Dock',
    anchor: lpurl + '#gate',
    external: true,
    order: 2,
    items: [
      {
        type: 'ai',
        name: 'Computer Vision Asset Tracking',
        anchor: 'https://www.navtrac.com/',
        external: true,
      },
      {
        type: 'tech',
        name: 'Driver Check-in/Out',
        anchor: lpurl + '#g_02',
        external: true,
      },
      {
        type: 'tech',
        name: 'Dock Scheduling',
        anchor: 'https://opendock.com/',
        external: true,
      },
      {
        type: 'tech',
        name: 'Yard Management (YMS)',
        anchor: lpurl + '#g_04',
        external: true,
      },
    ],
  },
  {
    title: 'Audit and Analytics',
    anchor: lpurl + '#audit',
    external: true,
    order: 3,
    items: [
      {
        type: 'tech',
        name: 'Freight Audit & Pay',
        anchor: lpurl + '#u_01',
        external: true,
      },
      {
        type: 'service-tech',
        name: 'Managed Analytics',
        anchor: lpurl + '#u_02',
        external: true,
      },
      {
        type: 'service',
        name: 'Market Intelligence and Econometrics',
        anchor: lpurl + '#u_03',
        external: true,
      },
    ],
  },
];

export function Services() {
  function renderIcon(type) {
    switch (type) {
      case 'service-tech':
        return <IconServiceTech width="32" height="32" />;
      case 'tech':
        return <IconTech width="32" height="32" />;
      case 'ai':
        return <IconAI width="32" height="32" />;
      default:
        return <IconService width="32" height="32" />;
    }
  }
  return (
    <ServicesWrapper>
      <Container>
        <Title>Next-Gen Logistics Solutions </Title>
        <Content>
          Everything your logistics org needs from the time an order is placed
          to final invoice of the shipment.
        </Content>
        <ServicesList>
          {services_list.map((branch, key) => {
            return (
              <ServicesContainer
                className={`block-` + branch.order}
                key={key + '-' + branch.title.trim()}
              >
                <ServicesTitle>
                  <a
                    href={branch.anchor}
                    target={branch.external ? `_blank` : `_self`}
                    rel={branch.external ? `noreferrer noopener` : ``}
                  >
                    {branch.title}
                  </a>
                </ServicesTitle>
                <ServiceItemsWrap>
                  {branch.items.map((service, key) => {
                    return (
                      <ServiceItem
                        key={
                          key +
                          '-' +
                          branch.title.trim() +
                          '-' +
                          service.name.trim()
                        }
                        as="a"
                        href={service.anchor}
                        target={service.external ? `_blank` : `_self`}
                        rel={service.external ? `noreferrer noopener` : ``}
                      >
                        {renderIcon(service.type)}
                        <span> {service.name}</span>
                      </ServiceItem>
                    );
                  })}
                </ServiceItemsWrap>
              </ServicesContainer>
            );
          })}
        </ServicesList>
        <Legend>
          <LegendItem>
            <IconService width="32" height="32" />
            <span>Services</span>
          </LegendItem>
          <LegendItem>
            <IconTech width="32" height="32" />
            <span>Technology</span>
          </LegendItem>
          <LegendItem>
            <IconServiceTech width="32" height="32" />
            <span>Service + Tech</span>
          </LegendItem>
          <LegendItem>
            <IconAI width="32" height="32" />
            <span>Artificial Intelligence</span>
          </LegendItem>
        </Legend>
        <ButtonWrapper>
          <MirandaGradientButton as="a" href={lpurl} target="_blank">
            <span>Learn More</span>
          </MirandaGradientButton>
        </ButtonWrapper>
      </Container>
    </ServicesWrapper>
  );
}
