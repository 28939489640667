/* eslint-disable @next/next/no-html-link-for-pages */
import React from 'react';

import { Container } from '@/components/Grid';

import {
  Layout,
  Content,
  LoadsmartTransitLogo,
  Title,
  Text,
  ResponsiveButton,
} from './BackgroundImageSection.styles';

import { WideImageOnMobile } from '@/components/Grid/Content';

export const BackgroundImageSection = () => (
  <Layout>
    <WideImageOnMobile
      hideOnMedium
      src={`/images/multimodal/fullscreen_section-small_image.png`}
    />
    <Container>
      <Content>
        <LoadsmartTransitLogo />
        <Title>The fastest way to price, book and track a shipment</Title>
        <Text>
          Transit, our award-winning web-based platform, is the simplest way to
          price, book and track your <a href="/ftl/">FTL</a>,{' '}
          <a
            target="_blank"
            href="https://lp.loadsmart.com/ltl-less-than-truckload-from-loadsmart"
            rel="noreferrer"
          >
            LTL
          </a>
          , and <a href="/port-drayage/">Port Drayage</a> shipments. Sign up now
          and get your first quote in seconds.
        </Text>
        <ResponsiveButton
          as="a"
          href={`${process.env.SHIPPERGUIDE_URL}/signup`}
        >
          Sign up
        </ResponsiveButton>
      </Content>
    </Container>
  </Layout>
);
