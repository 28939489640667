/* eslint-disable @next/next/no-html-link-for-pages */
import ReactPlayer from 'react-player';

//vendors
import { defaultOptions } from '@/vendor/HubspotFormIntegration';

//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';
import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';

//context containers
import {
  HeroSection,
  FeatureSection,
  BackgroundImageSection,
} from './containers/';

//components
import {
  CenteredWrapper as CenteredContentWrapper,
  ResponsiveImage,
  Title,
  Text,
  ReversedWrapper,
  VideoWrapper,
} from '@/components/Grid/Content';

// styles
import {
  SidePanels,
  CustomSidePanels,
  PageWrapper,
} from './PortDrayage.styles';

const PortDrayage = () => {
  return (
    <>
      <PageWrapper>
        <Navigation />
        <>
          <HeroSection />

          <CustomSidePanels reverse>
            <VideoWrapper>
              <ReactPlayer
                className="react-player"
                url="//www.youtube.com/watch?v=kcRVTRB5tHE?rel=0"
                controls
                width="100%"
                height="100%"
              />
            </VideoWrapper>
            <ReversedWrapper>
              <Title>
                Balancing people and technology to give the best options for
                your drayage freight
              </Title>
              <Text>
                Whether you want to quote & book online yourself through{' '}
                <a href="/shipper/shipperguide/">ShipperGuide TMS</a>, or
                through your account manager, Loadsmart works to ensure you have
                control and visibility from quoting to settlement.
              </Text>
            </ReversedWrapper>
          </CustomSidePanels>

          <HubspotForm {...defaultOptions}>
            <HubspotFormTitle>Ready to learn more?</HubspotFormTitle>
            <HubspotFormDescription>
              Fill out the form below and a member of the Loadsmart team will be
              in touch.
            </HubspotFormDescription>
          </HubspotForm>

          <FeatureSection />

          <SidePanels>
            <ResponsiveImage
              src={`/images/port-drayage/half_section-smarter_drayage-shipper-guide.png`}
              alt="Smarter Drayage"
            />
            <CenteredContentWrapper>
              <Title>Smart(er) Drayage</Title>
              <Text>
                Our platform connects data from port operators, ELD systems,
                3PLs and WMSs to give you real-time visibility into when a
                container is discharged, available or being held and when a
                truck gates out. This capability also enables us to ensure your
                containers are always returned on-time, avoiding unnecessary
                fees.
              </Text>
            </CenteredContentWrapper>
          </SidePanels>

          <BackgroundImageSection />
        </>
        <Footer />
      </PageWrapper>
    </>
  );
};

export default PortDrayage;
export { PortDrayage };
